import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import * as bgqcStyle from "./bgqc.module.css"
import { Container, Row, Col } from "react-bootstrap"

const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "bgqc.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
    <Container fluid="xl" className={bgqcStyle.home}>
        <Row>
          <Col>
            <h3 className={bgqcStyle.header}>ควบคุมคุณภาพ</h3>
            <p className={bgqcStyle.pstyles}>บริษัท ให้ความสำคัญการควบคุมคุณภาพอย่างเป็นระบบและเคร่งครัด ในเรื่องความปลอดภัยของบรรจุภัณฑ์และมาตรฐานในการผลิต</p>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: top left;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection